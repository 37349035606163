import { HOST_API } from 'src/config-global';
import { enqueueSnackbar } from 'notistack';

export const fetchData =
  <TData, TVariables>(
    query: string,
    variables?: TVariables,
    options?: RequestInit['headers']
  ): (() => Promise<TData>) =>
  async () => {
    const token = localStorage.getItem('extToken');

    const headers = {
      'Content-Type': 'application/json',
      ...(token && { Authorization: `Bearer ${token}` }),
      ...options,
    };

    const res = await fetch(HOST_API, {
      method: 'POST',
      headers: {
        ...headers,
      },
      body: JSON.stringify({
        query,
        variables,
      }),
    });

    const json = await res.json();

    if (json.errors) {
      const { message } = json.errors[0] || {};
      console.log('ERROR', json?.errors[0]);

      // Handling all errors
      enqueueSnackbar({ variant: 'error', message });

      // Handle if token is expired
      if (message == 'Could not verify JWT: JWTExpired') {
        localStorage.removeItem('extToken');
        window.location.reload();
      }

      throw new Error(message || 'Error…');
    }

    return json.data;
  };
