import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { AuthGuard } from 'src/auth/guard';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
// components
import { LoadingScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------

const IndexPage = lazy(() => import('src/pages/dashboard/statistics'));

// Suggestion History
const SuggestionsHistoryPage = lazy(() => import('src/pages/dashboard/suggestionsHistory'));
const SuggestionsHistoryDetailsPage = lazy(
  () => import('src/pages/dashboard/suggestionsHistory/SuggestionsHistoryDetails')
);

// Previews
const PreviewsPage = lazy(() => import('src/pages/dashboard/previews'));
const PreviewDetailsPage = lazy(() => import('src/pages/dashboard/previews/PreviewDetails'));

// Privacy
const PrivacyPage = lazy(() => import('src/pages/dashboard/privacy'));

// Account
const AccountPage = lazy(() => import('src/pages/dashboard/account'));

// Help
const HelpPage = lazy(() => import('src/pages/dashboard/help'));

// ----------------------------------------------------------------------

export const dashboardRoutes = [
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { element: <IndexPage />, index: true },
      { path: 'suggestions', element: <SuggestionsHistoryPage /> },
      { path: 'suggestions/:suggestionId', element: <SuggestionsHistoryDetailsPage /> },
      { path: 'previews', element: <PreviewsPage /> },
      { path: 'previews/:suggestionId', element: <PreviewDetailsPage /> },
      { path: 'privacy', element: <PrivacyPage /> },
      { path: 'account', element: <AccountPage /> },
      { path: 'help', element: <HelpPage /> },
    ],
  },
];
