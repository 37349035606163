// ----------------------------------------------------------------------

const ROOTS = {
  AUTH: '/auth',
  DASHBOARD: '/dashboard',
};

// ----------------------------------------------------------------------

export const paths = {
  minimalUI: 'https://mui.com/store/items/minimal-dashboard/',
  // AUTH
  auth: {
    jwt: {
      login: `${ROOTS.AUTH}/jwt/user-deletion`,
      register: `${ROOTS.AUTH}/jwt/register`,
    },
  },
  // DASHBOARD
  dashboard: {
    root: ROOTS.DASHBOARD,
    statistics: `${ROOTS.DASHBOARD}/statistics`,
    suggestions: `${ROOTS.DASHBOARD}/suggestions`,
    suggestionDetails: (suggestionId: string) => `${ROOTS.DASHBOARD}/suggestions/${suggestionId}`,
    previews: `${ROOTS.DASHBOARD}/previews`,
    previewDetails: (suggestionId: string) => `${ROOTS.DASHBOARD}/previews/${suggestionId}`,
    privacy: `${ROOTS.DASHBOARD}/privacy`,
    account: `${ROOTS.DASHBOARD}/account`,
    help: `${ROOTS.DASHBOARD}/help`,
  },
};
